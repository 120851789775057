import { createAction, props } from '@ngrx/store';
import { CredentialsActionDto } from '../../common/api-dtos/requests/credentials.dto';
import {UserApiDto} from "../../common/api-dtos/responses/User.api.dto";

export const LOG_IN_ACTION = '[Log In] Login';
export const LOG_IN_SUCCESS_ACTION = '[Log In] Log In Success';
export const LOG_IN_FAILURE_ACTION = '[Log In] Log In Failure';

export const login = createAction(
  LOG_IN_ACTION,
  props<{ credentials: CredentialsActionDto }>()
);

export const logInSuccess = createAction(
  LOG_IN_SUCCESS_ACTION,
  props<{ user: UserApiDto }>()
);

export const logInFailure = createAction(LOG_IN_FAILURE_ACTION);
